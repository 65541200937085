import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Wrapper, Article, BlogsCon} from "../styles/blogStyles"
import Sidemenu from "../components/sideMenu"
import {Container} from "../styles/productStyles"

export default ({ data: { nodeBlog } }) => {
    const { title, body: { processed: info } } = nodeBlog;
    return(
        <Layout>
            <SEO 
            title={title} 
            description={info}
            image={nodeBlog.relationships.field_featured_image.url}
            />
            <Container>
                <Wrapper>
                    <BlogsCon>
                        <Article>
                            <h1>{title}</h1>
                            <img style={{objectFit: "cover"}} src={nodeBlog.relationships.field_featured_image.url} alt=""/>
                            <p dangerouslySetInnerHTML={{ __html: nodeBlog.body.processed }}></p>
                        </Article>  
                    </BlogsCon>                     
                    <Sidemenu/>
                </Wrapper>
            </Container>
            
        </Layout>
    );
};

export const query = graphql`
    query BlogsQuery($slug: String!) {
        nodeBlog(fields: { slug: { eq: $slug } }) {
            title
            body {
                processed
            }
            relationships {
                field_featured_image {
                    url
                }
            }
        }
    }
`
